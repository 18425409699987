<div class="footer-block-container">
  <div class="footer-block">
    <h3 i18n="@@footerTitle">Ready to take off?</h3>

    <p i18n="@@footerP1">
      We help our clients become industry leaders in their respective fields by developing and implementing high-quality
      and innovative digital solutions.
    </p>

    <div class="row">
      <div class="col">
        <div class="row row-wrap">
          <div class="col col-auto">
            <strong i18n="@@footerPhone">Phone</strong>
            <a mat-button color="accent" href="tel:+32950289974">950-289-974</a>
          </div>

          <div class="col col-auto">
            <strong>Email</strong>
            <a mat-button color="accent" href="mailto:hello@coderty.com">hello&#64;coderty.com</a>
          </div>

          <div class="col col-100">
            <strong i18n="@@footerAddress">Come meet us</strong>
            <address>
              Calle Chillida 4, 2º 1 <br />04720 Roquetas de Mar,<br />
              Almería
            </address>
          </div>

          <div class="col col-100">
            <strong i18n="@@footerShare">Let's connect</strong>
            <ul class="share">
              <li>
                <a
                  mat-icon-button
                  href="https://twitter.com/codertystudio"
                  rel="noopener"
                  title="Follow us on Twitter"
                  target="_blank"
                >
                  <cdty-mask-icon svgIcon="twitter"></cdty-mask-icon>
                </a>
              </li>

              <li>
                <a
                  mat-icon-button
                  href="https://www.linkedin.com/company/coderty"
                  rel="noopener"
                  title="Follow us on Linkedin"
                  target="_blank"
                >
                  <cdty-mask-icon svgIcon="linkedin"></cdty-mask-icon>
                </a>
              </li>

              <li>
                <a
                  mat-icon-button
                  href="https://www.instagram.com/coderty"
                  rel="noopener"
                  title="Follow us on Instagram"
                  target="_blank"
                >
                  <cdty-mask-icon svgIcon="instagram"></cdty-mask-icon>
                </a>
              </li>

              <li>
                <a
                  mat-icon-button
                  href="https://youtube.com/@coderty"
                  rel="noopener"
                  title="Follow us on Youtube"
                  target="_blank"
                >
                  <cdty-mask-icon svgIcon="youtube"></cdty-mask-icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col col-auto">
        <ul>
          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/services"
              i18n-routerLink="@@servicesRouterLink"
              i18n="@@services"
              >Services</a
            >
          </li>

          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/methodology"
              i18n-routerLink="@@methodologyRouterLink"
              i18n="@@methodology"
              >Methodology</a
            >
          </li>

          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/technology"
              i18n-routerLink="@@technologyRouterLink"
              i18n="@@technology"
              >Technology</a
            >
          </li>

          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/digital-kit"
              i18n-routerLink="@@digitalKitRouterLink"
              i18n="@@digitalKit"
              >Digital Kit</a
            >
          </li>
        </ul>
      </div>

      <div class="col col-auto">
        <ul>
          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/consulting-kit"
              i18n-routerLink="@@consultingKitRouterLink"
              i18n="@@consultingKit"
            >
              Consulting Kit
            </a>
          </li>

          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/success-stories"
              i18n-routerLink="@@successStoriesRouterLink"
              i18n="@@successStories"
              >Success stories</a
            >
          </li>

          <li>
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/team"
              i18n-routerLink="@@teamRouterLink"
              i18n="@@team"
              >Team</a
            >
          </li>

          @if (currentLanguageCode === 'es') {
            <li>
              <a mat-button color="accent" title="Blob" href="/blog">Blog</a>
            </li>
          }

          <li class="contact">
            <a
              mat-button
              color="accent"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/contact"
              i18n-routerLink="@@contactRouterLink"
              i18n="@@contact"
              >Contact</a
            >
          </li>
        </ul>
      </div>

      <div class="col col-auto">
        <div class="row row-end">
          <div class="col col-auto">
            <picture class="almeria">
              <img
                ngSrc="assets/images/footer/camara-almeria.png"
                alt="Cámara de comercio de Almería"
                width="130"
                height="130"
              />
            </picture>
          </div>

          <div class="col col-auto">
            <picture class="pyme">
              <img
                ngSrc="assets/images/footer/pyme-2025-en.png"
                srcset="assets/images/footer/pyme-2025-en@2x.png 2x, assets/images/footer/pyme-2025-en.png 1x"
                i18n-src="@@pymeSrc"
                i18n-srcset="@@pymeSrcSet"
                alt="Digital Kit"
                width="100"
                height="130"
              />
            </picture>
          </div>
          <div class="col col-auto">
            <picture class="investigo">
              <img
                ngSrc="assets/images/footer/programa-investigo.png"
                alt="Programa Investigo"
                width="130"
                height="130"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script>
    console.log('vgo');
    (function (e, t, o, n, p, r, i) {
      e[n] =
        e[n] ||
        function () {
          (e[n].q = e[n].q || []).push(arguments);
        };
      r = t.createElement(o);
      i = t.getElementsByTagName(o)[0];
      r.async = 1;
      r.src = p;
      i.parentNode.insertBefore(r, i);
    })(window, document, 'script', 'vgo', 'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js');
    vgo('setAccount', '652413545');
    vgo('setTrackByDefault', true);
    vgo('process');
    console.log(vgo, 'vgo');
  </script>
</div>

<div class="footer-copyright">
  <div class="copyright" i18n="@@copyrightText">
    © {{ currentYear }}, <strong>Coderty</strong> S.L. All Rights Reserved
  </div>
  <div class="by">
    <span i18n="@@madeWith"> Made with <cdty-icon svgIcon="love"></cdty-icon> </span>
    <span i18n="@@byOurTeam">
      by our
      <strong i18n-routerLink="@@teamRouterLink">team</strong>
    </span>
  </div>

  <div class="legal-links">
    <a routerLink="/privacy-and-terms" i18n-routerLink="@@privacyAndTermsRouterLink" i18n="@@privacyAndTerms">
      Privacy Policy
    </a>
    |
    <a routerLink="/legal-warning" i18n-routerLink="@@legalWarningRouterLink" i18n="@@legalWarning"> Legal Warning </a>
    |
    <a routerLink="/cookies" i18n="@@cookiesPolicy"> Cookies Policy </a>
  </div>
</div>

import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { IconComponent, MaskIconComponent } from '@coderty-web/front/shared/icon';

@Component({
  selector: 'cdty-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgOptimizedImage, MaskIconComponent, RouterModule, IconComponent, MatButtonModule]
})
export class FooterComponent {
  @Input() currentLanguageCode = 'es';
  currentYear = new Date().getFullYear();
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutConfig } from './interfaces/layout-config.interface';
import { SeoService } from '@coderty-web/front/shared/seo';

const DEFAULT_TITLE = 'Coderty Studio';
// eslint-disable-next-line max-len
const DEFAULT_DESCRIPTION = $localize`:@@codertyMetaDescription:We are a development studio and technological consultant with over 5 years of experience. We create / drive / improve / bring to life your business model, whatever its size.`;
const DEFAULT_NAV_MODE = 'light';
const DEFAULT_KEYWORDS: string[] = [
  $localize`:@@keywordCustomSoftware:Custom software`,
  $localize`:@@keywordSoftwareDevelopment:Software development`,
  $localize`:@@keywordITConsultingFirm:IT consulting firm`,
  $localize`:@@keywordWebApps:Web apps`,
  $localize`:@@keywordCloud:Cloud`,
  $localize`:@@keywordCloudComputing:Cloud Computing`,
  'Angular',
  'NodeJS',
  'PWA',
  'Coderty'
];
const DEFAULT_SHOW_HEADER = true;
const DEFAULT_SHOW_FOOTER = true;

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  config$ = new BehaviorSubject<LayoutConfig>({
    title: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
    navMode: DEFAULT_NAV_MODE,
    keywords: DEFAULT_KEYWORDS,
    showHeader: DEFAULT_SHOW_HEADER,
    showFooter: DEFAULT_SHOW_FOOTER
  });

  constructor(private seoService: SeoService) {}

  setConfig(config?: LayoutConfig) {
    const newConfig: LayoutConfig = {
      title: config?.title ? config.title : DEFAULT_TITLE,
      description: config?.description || DEFAULT_DESCRIPTION,
      url: config?.url,
      alternateUrl: config?.alternateUrl,
      navMode: config?.navMode || DEFAULT_NAV_MODE,
      keywords: config?.keywords?.length ? config.keywords : DEFAULT_KEYWORDS,
      showHeader:
        config?.showHeader === true || config?.showHeader === false ? config?.showHeader : DEFAULT_SHOW_HEADER,
      showFooter: config?.showFooter === true || config?.showFooter === false ? config?.showFooter : DEFAULT_SHOW_FOOTER
    };

    this.config$.next(newConfig);

    this.seoService.setConfig({
      title: newConfig.title,
      description: newConfig.description,
      url: newConfig?.url,
      alternateUrl: newConfig?.alternateUrl,
      keywords: newConfig.keywords
    });
  }
}

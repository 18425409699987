import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';

import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IconsService } from '../../icons.service';

@Component({
  selector: 'cdty-icon',
  standalone: true,
  imports: [],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  @Input() svgIcon?: string;
  iconStyles?: SafeStyle;

  constructor(
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private iconsService: IconsService
  ) {}

  ngOnChanges(): void {
    this.setIcon();
  }

  private setIcon(): void {
    const icon = (this.iconsService.icons ?? []).find(icon => icon?.name === this?.svgIcon);
    this.iconStyles = this.sanitizer.bypassSecurityTrustStyle(
      `
        background-image: url(/${icon?.path});
      `
    );
    this.cdr.markForCheck();
  }
}

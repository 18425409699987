import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  icons: { name: string; path: string }[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  init(): void {
    // Header icons

    this.initIcon('logotype', 'assets/images/logotype.svg');
    this.initIcon('twitter', 'assets/images/header/twitter.svg');
    this.initIcon('linkedin', 'assets/images/header/linkedin.svg');
    this.initIcon('instagram', 'assets/images/header/instagram.svg');
    this.initIcon('youtube', 'assets/images/header/youtube.svg');

    // Home icons

    this.initIcon('arrow-prev', 'assets/images/home/arrow-prev.svg');
    this.initIcon('arrow-next', 'assets/images/home/arrow-next.svg');
    this.initIcon('angular', 'assets/images/home/angular.svg');
    this.initIcon('node-js', 'assets/images/home/node-js.svg');

    // Services icons

    this.initIcon('nodejs-angular-pwa-stencil', 'assets/images/services/nodejs-angular-pwa-stencil.svg');
    this.initIcon('monitoring', 'assets/images/services/monitoring.svg');
    this.initIcon('code-analysis', 'assets/images/services/code-analysis.svg');
    this.initIcon('competition-analysis', 'assets/images/services/competition-analysis.svg');
    this.initIcon('consulting', 'assets/images/services/consulting.svg');
    this.initIcon('route-map', 'assets/images/services/route-map.svg');
    this.initIcon('management', 'assets/images/services/management.svg');
    this.initIcon('planification', 'assets/images/services/planification.svg');
    this.initIcon('auto-scaling-on-demand', 'assets/images/services/auto-scaling-on-demand.svg');
    this.initIcon('iaas', 'assets/images/services/iaas.svg');
    this.initIcon('serverless-application', 'assets/images/services/serverless-application.svg');
    this.initIcon('cloud-solutions-architecture', 'assets/images/services/cloud-solutions-architecture.svg');
    this.initIcon('distributed-apps', 'assets/images/services/distributed-apps.svg');

    // Technology icons

    this.initIcon('javascript', 'assets/images/technology/javascript.svg');
    this.initIcon('typescript', 'assets/images/technology/typescript.svg');
    this.initIcon('angular-ionic', 'assets/images/technology/angular-ionic.svg');
    this.initIcon('nodejs-express', 'assets/images/technology/nodejs-express.svg');

    // Footer icons

    this.initIcon('isotype', 'assets/images/isotype.svg');
    this.initIcon('love', 'assets/images/footer/love.svg');
    this.initIcon('beer', 'assets/images/footer/beer.svg');

    // Landings icons

    this.initIcon('landing-mobile', 'assets/images/landings/icon-mobile.png');
    this.initIcon('landing-cloud', 'assets/images/landings/icon-cloud.png');
    this.initIcon('landing-software', 'assets/images/landings/software.svg');
    this.initIcon('landing-methodology', 'assets/images/landings/methodology.svg');
    this.initIcon('landing-focus', 'assets/images/landings/focus.svg');
    this.initIcon('landing-play', 'assets/images/landings/play.svg');
  }

  private initIcon(name: string, path: string): void {
    this.icons.push({ name, path });
  }
}

import { Route } from '@angular/router';

const currentLanguageCode = $localize`:@@currentLanguageCode:en`;

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('@coderty-web/front/home').then(m => m.FrontHomeModule)
  },
  {
    path: $localize`:@@servicesPath:services`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.ServicesModule)
  },
  {
    path: $localize`:@@methodologyPath:methodology`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.MethodologyModule)
  },
  {
    path: $localize`:@@technologyPath:technology`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.TechnologyModule)
  },
  {
    path: $localize`:@@digitalKitPath:digital-kit`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.DigitalKitModule)
  },
  {
    path: $localize`:@@consultingKitPath:consulting-kit`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.ConsultingKitModule)
  },
  {
    path: $localize`:@@successStoriesPath:success-stories`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.SuccessStoriesModule)
  },
  {
    path: $localize`:@@teamPath:team`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.TeamModule)
  },
  {
    path: $localize`:@@contactPath:contact`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.ContactModule)
  },
  {
    path: $localize`:@@privacyAndTermsPath:privacy-and-terms`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.PrivacyAndTermsModule)
  },
  {
    path: $localize`:@@legalWarningPath:legal-warning`,
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.LegalWarningModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.CookiesModule)
  },
  ...(currentLanguageCode === 'es'
    ? [
        {
          path: 'desarrollo-software-para-empresas',
          loadComponent: async () => (await import('@coderty-web/front/landings')).LandingCompaniesComponent
        },
        {
          path: 'mvp-desarrollo-software-para-startups',
          loadComponent: async () => (await import('@coderty-web/front/landings')).LandingStartupsComponent
        }
      ]
    : []),
  {
    path: '**',
    loadChildren: () => import('@coderty-web/front/pages').then(m => m.NotFoundModule)
  }
];

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" [fixedInViewport]="true" position="end">
    <nav>
      <ul class="translations">
        <li><a mat-button rel="noopener" href="/en/" [disabled]="currentLanguageCode === 'en'">ENG</a></li>
        <li>/</li>
        <li><a mat-button rel="noopener" href="/" [disabled]="currentLanguageCode === 'es'">ESP</a></li>
      </ul>

      <ul>
        <li>
          <a mat-button (click)="sidenav.close()" routerLink="/" i18n="@@home">Home</a>
        </li>
        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/services"
            i18n-routerLink="@@servicesRouterLink"
            i18n="@@services"
          >
            Services
          </a>
        </li>

        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/methodology"
            i18n-routerLink="@@methodologyRouterLink"
            i18n="@@methodology"
          >
            Methodology
          </a>
        </li>

        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/technology"
            i18n-routerLink="@@technologyRouterLink"
            i18n="@@technology"
          >
            Technology
          </a>
        </li>

        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/digital-kit"
            i18n-routerLink="@@digitalKitRouterLink"
            i18n="@@digitalKit"
          >
            Digital Kit
          </a>
        </li>

        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/consulting-kit"
            i18n-routerLink="@@consultingKitRouterLink"
            i18n="@@consultingKit"
          >
            Consulting Kit
          </a>
        </li>

        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/success-stories"
            i18n-routerLink="@@successStoriesRouterLink"
            i18n="@@successStories"
          >
            Success stories
          </a>
        </li>

        <li>
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/team"
            i18n-routerLink="@@teamRouterLink"
            i18n="@@team"
          >
            Team
          </a>
        </li>

        @if (currentLanguageCode === 'es') {
          <li>
            <a mat-button title="Blob" href="/blog">Blog</a>
          </li>
        }

        <li class="contact">
          <a
            mat-button
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="sidenav.close()"
            routerLink="/contact"
            i18n-routerLink="@@contactRouterLink"
            i18n="@@contact"
          >
            Contact
          </a>
        </li>

        <li class="share">
          <a
            mat-icon-button
            href="https://twitter.com/codertystudio"
            rel="noopener"
            title="Follow us on Twitter"
            target="_blank"
          >
            <cdty-mask-icon svgIcon="twitter" />
          </a>

          <a
            mat-icon-button
            href="https://www.linkedin.com/company/coderty"
            rel="noopener"
            title="Follow us on Linkedin"
            target="_blank"
          >
            <cdty-mask-icon svgIcon="linkedin" />
          </a>

          <a
            mat-icon-button
            href="https://www.instagram.com/coderty"
            rel="noopener"
            title="Follow us on Instagram"
            target="_blank"
          >
            <cdty-mask-icon svgIcon="instagram" />
          </a>

          <a
            mat-icon-button
            href="https://youtube.com/@coderty"
            rel="noopener"
            title="Follow us on Youtube"
            target="_blank"
          >
            <cdty-mask-icon svgIcon="youtube" />
          </a>
        </li>

        <li class="legal-link">
          <a
            mat-button
            (click)="sidenav.close()"
            routerLink="/privacy-and-terms"
            i18n-routerLink="@@privacyAndTermsRouterLink"
            i18n="@@privacyAndTerms"
            >Privacy & Terms</a
          >
        </li>

        <li class="legal-link">
          <a
            mat-button
            (click)="sidenav.close()"
            routerLink="/legal-warning"
            i18n-routerLink="@@legalWarningRouterLink"
            i18n="@@legalWarning"
            >Legal Warning</a
          >
        </li>

        <li class="legal-link">
          <a mat-button (click)="sidenav.close()" routerLink="/cookies" i18n="@@cookiesPolicy">Cookies Policy</a>
        </li>
      </ul>
    </nav>
  </mat-sidenav>

  <mat-sidenav-content>
    @if (showHeader) {
      <header [ngClass]="{ light: !isDarkNav, dark: isDarkNav, 'header-scrolled': isScrolled }">
        <div>
          <a class="logo" (click)="sidenav.close()" routerLink="/" title="Coderty Studio">
            <cdty-mask-icon svgIcon="logotype" />
          </a>
        </div>

        <nav>
          <ul class="translations desktop">
            <li><a mat-button rel="noopener" href="/en/" [disabled]="currentLanguageCode === 'en'">ENG</a></li>
            <li>/</li>
            <li><a mat-button rel="noopener" href="/" [disabled]="currentLanguageCode === 'es'">ESP</a></li>
          </ul>

          <ul class="desktop">
            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/services"
                i18n-routerLink="@@servicesRouterLink"
                i18n="@@services"
              >
                Services
              </a>
            </li>

            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/methodology"
                i18n-routerLink="@@methodologyRouterLink"
                i18n="@@methodology"
              >
                Methodology
              </a>
            </li>

            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/technology"
                i18n-routerLink="@@technologyRouterLink"
                i18n="@@technology"
              >
                Technology
              </a>
            </li>

            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/digital-kit"
                i18n-routerLink="@@digitalKitRouterLink"
                i18n="@@digitalKit"
              >
                Digital Kit
              </a>
            </li>

            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/consulting-kit"
                i18n-routerLink="@@consultingKitRouterLink"
                i18n="@@consultingKit"
              >
                Consulting Kit
              </a>
            </li>

            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/success-stories"
                i18n-routerLink="@@successStoriesRouterLink"
                i18n="@@successStories"
              >
                Success stories
              </a>
            </li>

            <li>
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/team"
                i18n-routerLink="@@teamRouterLink"
                i18n="@@team"
              >
                Team
              </a>
            </li>

            @if (currentLanguageCode === 'es') {
              <li>
                <a mat-button title="Blob" href="/blog">Blog</a>
              </li>
            }

            <li class="contact">
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="sidenav.close()"
                routerLink="/contact"
                i18n-routerLink="@@contactRouterLink"
                i18n="@@contact"
              >
                Contact
              </a>
            </li>

            <li class="share">
              <a
                mat-icon-button
                href="https://twitter.com/codertystudio"
                rel="noopener"
                title="Follow us on Twitter"
                target="_blank"
              >
                <cdty-mask-icon svgIcon="twitter" />
              </a>
            </li>

            <li class="share">
              <a
                mat-icon-button
                href="https://www.linkedin.com/company/coderty"
                rel="noopener"
                title="Follow us on Linkedin"
                target="_blank"
              >
                <cdty-mask-icon svgIcon="linkedin" />
              </a>
            </li>

            <li class="share">
              <a
                mat-icon-button
                href="https://www.instagram.com/coderty"
                rel="noopener"
                title="Follow us on Instagram"
                target="_blank"
              >
                <cdty-mask-icon svgIcon="instagram" />
              </a>
            </li>

            <li class="share">
              <a
                mat-icon-button
                href="https://youtube.com/@coderty"
                rel="noopener"
                title="Follow us on Youtube"
                target="_blank"
              >
                <cdty-mask-icon svgIcon="youtube" />
              </a>
            </li>
          </ul>

          <ul class="mobile">
            <li>
              <button mat-icon-button (click)="sidenav.open()" aria-label="Menu">
                <mat-icon>menu</mat-icon>
              </button>
            </li>
          </ul>
        </nav>
      </header>
    }

    <main>
      <ng-content></ng-content>
    </main>

    @if (showFooter) {
      <footer>
        <cdty-footer [currentLanguageCode]="currentLanguageCode"></cdty-footer>
      </footer>
    }
  </mat-sidenav-content>
</mat-sidenav-container>

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SeoConfig } from './seo.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private router: Router,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {}

  setConfig(config: SeoConfig) {
    if (config.title && config.description) {
      // BASIC TAGS

      const url = config.url || this.document.defaultView?.location?.href;
      const alternateUrl = config.alternateUrl || this.document.defaultView?.location?.href;

      this.title.setTitle(config.title);
      this.meta.updateTag({ name: 'title', content: config.title }, 'name="title"');
      this.meta.updateTag({ name: 'description', content: config.description }, 'name="description"');
      this.meta.updateTag({ name: 'keywords', content: (config.keywords || []).join(', ') }, 'name="keywords"');
      this.meta.updateTag(
        {
          name: 'url',
          content: url
        },
        'name="url"'
      );

      // FACEBOOK TAGS

      this.meta.updateTag({ property: 'og:title', content: config.title }, 'property="og:title"');
      this.meta.updateTag(
        {
          property: 'og:url',
          content: url
        },
        'property="og:url"'
      );
      this.meta.updateTag({ property: 'og:description', content: config.description }, 'property="og:description"');
      this.meta.updateTag(
        {
          property: 'og:image',
          content: '/assets/external/share/og.jpg'
        },
        'property="og:image"'
      );

      // TWITTER TAGS

      this.meta.updateTag({ property: 'twitter:title', content: config.title }, 'property="twitter:title"');
      this.meta.updateTag(
        {
          property: 'twitter:url',
          content: url
        },
        'property="twitter:url"'
      );
      this.meta.updateTag(
        { property: 'twitter:description', content: config.description },
        'property="twitter:description"'
      );
      this.meta.updateTag(
        {
          property: 'twitter:image',
          content: '/assets/external/share/twitter.jpg'
        },
        'property="twitter:image"'
      );

      const alternateEnElement: HTMLLinkElement | null = this.document.querySelector(
        'link[rel="alternate"][hreflang="en"]'
      );

      const alternateEsElement: HTMLLinkElement | null = this.document.querySelector(
        'link[rel="alternate"][hreflang="es"]'
      );

      if (alternateEnElement && alternateEsElement) {
        if (this.localeId === 'es') {
          alternateEsElement.setAttribute('href', url);
          alternateEnElement.setAttribute('href', alternateUrl);
        } else {
          alternateEnElement.setAttribute('href', url);
          alternateEsElement.setAttribute('href', alternateUrl);
        }
      }

      // this.removeCanonicalUrls();
      // this.addCanonicalUrl(url);
    }
  }

  private removeCanonicalUrls(): void {
    this.document.head.querySelectorAll('link[rel="canonical"]')?.forEach(element => element.remove());
  }

  private addCanonicalUrl(url: string): void {
    const link = this.document.createElement('link');
    link.rel = 'canonical';
    link.href = url;
    this.document.head.appendChild(link);
  }
}

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { MaskIconComponent } from '@coderty-web/front/shared/icon';
import { FooterComponent } from '../../components/footer/footer.component';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'cdty-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FooterComponent, MaskIconComponent, RouterModule, MatSidenavModule, NgClass, MatButtonModule, MatIconModule]
})
export class LayoutComponent {
  currentLanguageCode = $localize`:@@currentLanguageCode:en`;
  isDarkNav = false;
  showHeader = true;
  showFooter = true;
  isScrolled = false;

  constructor() {
    inject(LayoutService).config$.subscribe(config => {
      this.isDarkNav = config.navMode === 'dark';
      this.showHeader = config.showHeader;
      this.showFooter = config.showFooter;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.isScrolled = window.scrollY > 50;
  }
}

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  InMemoryScrollingFeature,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling
} from '@angular/router';
import { APP_CONFIG } from '@coderty-web/front/shared/cdty-app-config';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling({
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled'
});

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    { provide: APP_CONFIG, useValue: environment },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideClientHydration(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), inMemoryScrollingFeature)
  ]
};

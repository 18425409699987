import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LayoutComponent } from '@coderty-web/front/layout';
import { IconsService } from '@coderty-web/front/shared/icon';

@Component({
  selector: 'cdty-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [LayoutComponent, RouterOutlet]
})
export class AppComponent {
  constructor(
    private iconsService: IconsService,
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.iconsService.init();
    if (this.document?.documentElement) {
      this.document.documentElement.lang = this.localeId;
    }
  }
}
